import config from '@/apex-config'
export default {
  menu: [
    { title: 'Download', icon: 'mdi-download' },
    { title: 'Print', icon: 'mdi-printer' },
    // { title: 'Duplicate', icon: 'mdi-content-copy' },
    // { title: 'Delete', icon: 'mdi-delete' },
  ],
  apexLine1: {
    options: {
      chart: {
        type: 'line',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      colors: [config.light.primary],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [2, 2],
        curve: 'smooth',
        dashArray: [0, 8, 5],
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
      },
      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val
              },
            },
          },
        ],
      },
      legend: {
        show: false,
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
    },
    series: [
      {
        name: 'Reach:',
        data: [
          7000,
          6003,
          8004,
          7009,
          7000,
          6005,
          8000,
          7000,
          6003,
          8004,
          7009,
          7000,
        ],
      },
    ],
  },
  apexLine2: {
    options: {
      chart: {
        type: 'line',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      colors: [config.light.success],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [2, 2],
        curve: 'smooth',
        dashArray: [0, 8, 5],
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
      },
      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val
              },
            },
          },
        ],
      },
      legend: {
        show: false,
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
    },
    series: [
      {
        name: 'Reach:',
        data: [
          7000,
          6003,
          8004,
          7009,
          7000,
          6005,
          8000,
          7000,
          6003,
          8004,
          7009,
          7000,
        ],
      },
    ],
  },
  apexLine3: {
    options: {
      chart: {
        type: 'line',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      colors: [config.light.secondary],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [2, 2],
        curve: 'smooth',
        dashArray: [0, 8, 5],
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
      },
      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val
              },
            },
          },
        ],
      },
      legend: {
        show: false,
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
    },
    series: [
      {
        name: 'Reach:',
        data: [
          7000,
          6003,
          8004,
          7009,
          7000,
          6005,
          8000,
          7000,
          6003,
          8004,
          7009,
          7000,
        ],
      },
    ],
  },
  apexLine4: {
    options: {
      chart: {
        type: 'line',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      colors: [config.light.info],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [2, 2],
        curve: 'smooth',
        dashArray: [0, 8, 5],
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
      },
      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val
              },
            },
          },
        ],
      },
      legend: {
        show: false,
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
    },
    series: [
      {
        name: 'Reach:',
        data: [
          7000,
          6003,
          8004,
          7009,
          7000,
          6005,
          8000,
          7000,
          6003,
          8004,
          7009,
          7000,
        ],
      },
    ],
  },
  apexArea: {
    options: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      colors: [config.light.primary, config.light.secondary],
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        type: 'date',
        categories: [],
      },
      yaxis: [
        {
          show: true,
          // title: {
          //   text: 'Impressions',
          // },
        },
        {
          opposite: true,
          show: true,
          // title: {
          //   text: 'Clicks',
          // },
        },
      ],
      tooltip: {
        x: {
          format: 'dd/MM/yy',
        },
      },
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'left',
      },
      fill: {
        type: 'solid',
        opacity: 0.2,
        colors: [config.light.primary, config.light.secondary],
      },
      stroke: {
        width: 2,
        curve: 'smooth',
      },
    },
    series: [
      {
        name: 'Clicks',
        data: [],
      },
      {
        name: 'CTR',
        data: [],
      },
    ],
  },
  apexAreaCPC: {
    options: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      colors: [config.light.primary, config.light.secondary],
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        type: 'date',
        categories: [],
      },
      yaxis: [
        {
          show: true,
          // title: {
          //   text: 'Impressions',
          // },
        },
        {
          opposite: true,
          show: true,
          // title: {
          //   text: 'Clicks',
          // },
        },
      ],
      tooltip: {
        x: {
          format: 'dd/MM/yy',
        },
      },
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'left',
      },
      fill: {
        type: 'solid',
        opacity: 0.2,
        colors: [config.light.primary, config.light.secondary],
      },
      stroke: {
        width: 2,
        curve: 'smooth',
      },
    },
    series: [
      {
        name: 'Cost',
        data: [],
      },
      {
        name: 'Avg.CPC',
        data: [],
      },
    ],
  },
  apexLines: {
    options: {
      chart: {
        type: 'line',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      colors: [config.light.primary, config.light.warning],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [2, 2],
        curve: 'smooth',
        dashArray: [0, 8, 5],
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories: [],
      },
      yaxis: [
        {
          show: true,
          // title: {
          //   text: 'Impressions',
          // },
        },
        {
          opposite: true,
          show: true,
          // title: {
          //   text: 'Clicks',
          // },
        },
      ],
      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val
              },
            },
          },
        ],
      },
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'left',
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
    },
    series: [
      {
        name: 'Ad Impressions',
        data: [],
      },
      {
        name: 'Ad Clicks',
        data: [],
      },
    ],
  },
  apexPie: {
    options: {
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          customScale: 0.85,
          donut: {
            size: '60%',
          },
        },
      },
      colors: [
        config.light.primary,
        config.light.warning,
        config.light.secondary,
      ],
      labels: ['App', 'Web', 'Other'],
      legend: {
        position: 'right',
        offsetY: 100,
        offsetX: 36,
        markers: {
          width: 12,
          height: 12,
        },
        formatter: function (seriesName, opts) {
          return seriesName + ':  ' + opts.w.globals.series[opts.seriesIndex]
        },
      },
      stroke: {
        width: 4,
      },
    },
    series: [20, 50, 5],
  },
  apexRadial: {
    options: {
      plotOptions: {
        radialBar: {
          offsetY: 20,
          startAngle: 90,
          endAngle: -270,
          hollow: {
            margin: 0,
            size: '30%',
            background: 'transparent',
            image: undefined,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            },
          },
        },
      },
      colors: [
        config.light.primary,
        config.light.secondary,
        config.light.warning,
      ],
      stroke: {
        lineCap: 'round',
      },
      labels: ['Mobile', 'Tablet', 'Desktop'],
      legend: {
        show: true,
        floating: true,
        // fontSize: '16px',
        position: 'right',
        offsetX: 104,
        offsetY: 30,
        labels: {
          useSeriesColors: true,
        },
        markers: {
          size: 0,
        },
        formatter: function (seriesName, opts) {
          return seriesName + ':  ' + opts.w.globals.series[opts.seriesIndex]
        },
        itemMargin: {
          vertical: 2,
        },
      },
    },
    series: [76, 67, 61],
  },
  table: {
    headers: [
      {
        text: 'Campaign Name',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      { text: 'Agency', value: 'agency' },
      { text: 'Impressions', value: 'impressions' },
      { text: 'Clicks', value: 'clicks' },
      { text: 'CPM', value: 'cpm' },
      { text: 'CTR', value: 'ctr' },
      { text: 'CPC', value: 'cpc' },
      { text: 'Spend', value: 'spend' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    data: [],
  },
}
