<template>
  <v-card class="mt-5" outlined>
    <v-card-title>
      <v-row no-gutters>
        <v-col cols="3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>

        <v-spacer></v-spacer>
        <v-col cols="3">
          <v-select
            v-model="headersSelected"
            :items="headers"
            return-object
            dense
            outlined
            :menu-props="{ maxHeight: '400', bottom: true, offsetY: true }"
            hide-details
            label="Select Columns"
            multiple
            append-icon="mdi-chevron-down"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip x-small v-if="index === 1">
                <span>{{ item.text }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ headersSelected.length - 1 }} others)
              </span>
            </template></v-select
          >
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider></v-divider>
    <v-data-table
      :headers="headersSelected"
      :items="campaigns"
      v-if="campaigns"
      :search="search"
      :loading="loading"
      loading-text="Loading... Please wait"
      hide-default-footer
      :items-per-page="filter_params.per_page"
      item-key="id"
    >
      <template #[`item.campaign`]="{ item }">
        <router-link
          :to="{
            name: 'report',
            params: {
              id: item.campaign_id,
            },
          }"
        >
          <span class="font-weight-bold">
            {{ item.campaign }}
          </span>
        </router-link>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.activity_status="{ item }">
        <v-chip
          v-if="item.activity_status === null"
          color="grey lighten-5"
          text-color="grey"
          class="font-weight-bold"
          small
        >
          <v-icon x-small left>mdi-circle</v-icon>Incomplete
        </v-chip>
        <v-chip
          v-else-if="item.activity_status == 'Active'"
          color="green lighten-5"
          text-color="success"
          class="font-weight-bold"
          small
        >
          <v-icon x-small left>mdi-circle</v-icon>{{ item.activity_status }}
        </v-chip>
        <v-chip
          v-else-if="item.activity_status == 'Paused'"
          color="grey lighten-5"
          text-color="grey"
          class="font-weight-bold"
          small
        >
          <v-icon x-small left>mdi-circle</v-icon>{{ item.activity_status }}
        </v-chip>
        <v-chip
          v-else-if="item.activity_status == 'In Review'"
          color="orange lighten-5"
          text-color="warning"
          class="font-weight-bold"
          small
        >
          <v-icon x-small left>mdi-circle</v-icon>{{ item.activity_status }}
        </v-chip>
        <v-chip
          v-else-if="item.activity_status == 'Completed'"
          color="cyan lighten-5"
          text-color="cyan"
          class="font-weight-bold"
          small
        >
          <v-icon x-small left>mdi-circle</v-icon>{{ item.activity_status }}
        </v-chip>
        <v-chip
          v-else
          color="red lighten-5"
          text-color="error"
          class="font-weight-bold"
          small
        >
          <v-icon x-small left>mdi-circle</v-icon>{{ item.activity_status }}
        </v-chip>
      </template>
      <template #[`item.campaign_type`]="{ item }">
        <span class="text-capitalize">{{
          item.campaign_type ? item.campaign_type : "-"
        }}</span>
      </template>
      <template #[`item.campaign_objective`]="{ item }">
        <span class="text-capitalize">{{
          item.campaign_objective ? item.campaign_objective : "-"
        }}</span>
      </template>
      <template #[`item.bid_strategy`]="{ item }">
        <span class="text-capitalize">{{
          item.bid_strategy ? item.bid_strategy.toUpperCase() : "-"
        }}</span>
      </template>

      <template #[`item.impressions`]="{ item }">
        {{ item.impressions ? item.impressions.toLocaleString() : 0 }}
      </template>
      <template #[`item.pacing`]="{ item }">
        <div class="d-flex justify-space-between">
          <span style="font-size: 9px">{{ checkValues(item) }} </span>
          <span style="font-size: 9px"
            >{{ Math.ceil(pacingValue(item)) }}%</span
          >
        </div>

        <v-progress-linear
          :value="pacingValue(item)"
          rounded
          height="4"
          :color="pacingPerformance(item)"
        >
        </v-progress-linear>
      </template>
      <template #[`item.clicks`]="{ item }">
        {{ checkClicks(item) }}
      </template>
      <template #[`item.cpc`]="{ item }"> {{ checkCPC(item) }} </template>

      <template #[`item.ctr`]="{ item }">
        {{ checkCTR(item) }}
      </template>
      <template #[`item.cpl`]="{ item }">
        {{ checkCPL(item) }}
      </template>

      <template #[`item.campaign_spend`]="{ item }">
        {{
          parseFloat(spend(item)).toLocaleString("en-us", {
            style: "currency",
            currency: "USD",
          })
        }}
      </template>

      <template #[`item.leads`]="{ item }">
        {{ checkLeads(item) }}
      </template>

      <template #[`item.views`]="{ item }">
        {{ checkViews(item) }}
      </template>

      <template #[`item.vtr`]="{ item }">
        {{ checkVTR(item) }}
      </template>

      <template #[`item.engagements`]="{ item }">
        {{ checkEngagements(item) }}
      </template>

      <template #[`item.engagement_rate`]="{ item }">
        {{ checkEngagementRate(item) }}
      </template>
    </v-data-table>
    <v-divider></v-divider>

    <v-card-actions class="d-flex align-center pa-4">
      <div class="d-flex align-center">
        <div class="caption mr-3">
          Showing page {{ pagination.page }} of {{ pagination.totalItems }}
        </div>
        <div>
          <v-select
            v-if="campaigns.length"
            v-model="filter_params.per_page"
            outlined
            :items="size"
            label="Rows Per Page"
            dense
            hide-details
          ></v-select>
        </div>
      </div>
      <v-spacer></v-spacer>

      <v-pagination
        v-model="pagination.page"
        :length="pagination.totalItems"
        :total-visible="10"
        prev-icon="mdi-arrow-left"
        next-icon="mdi-arrow-right"
        color="primary"
        class="custom"
        @input="handlePageChange"
      ></v-pagination>
    </v-card-actions>
  </v-card>
</template>

<script>
import mocker from "../../api/mocker";
export default {
  props: {
    headers: Array,
    campaigns: Array,
    pagination: {
      type: Object,
    },
    loading: Boolean,
  },
  components: {},
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dates: ["2021-01-01", "2023-12-31"],
      modal: false,
      dateMenu: false,
      mocker,
      search: "",
      apexLoading: false,
      items: ["Foo", "Bar", "Fizz", "Buzz"],
      filter_params: {
        per_page: 10,
        page: 1,
      },
      size: [10, 25, 50, 200],
      headersSelected: [],
    };
  },
  methods: {
    populateHeaders() {
      this.headersSelected = this.headers;
    },
    spend(item) {
      let budget = item.budget ? item.budget : 0;
      let spend = item.campaign_spend ? item.campaign_spend : 0;

      if (!this.superAdmin && spend > budget) {
        spend = budget;
      }
      return spend;
    },
    checkValues(item) {
      let spend = this.spend(item);
      let budget = item.budget ? item.budget : 0;

      if (!this.superAdmin && spend > budget) {
        spend = budget;
      }

      return (
        Number(spend).toLocaleString("en-us", {
          style: "currency",
          currency: "USD",
        }) +
        " / " +
        Number(budget).toLocaleString("en-us", {
          style: "currency",
          currency: "USD",
        })
      );
    },
    daysLeft(endDate) {
      //get todays date
      let today = new Date();

      // convert to mm/dd/yy
      let end = new Date(endDate);

      const daysLeft = end.getTime() - today.getTime();
      return daysLeft > 0 ? daysLeft / (1000 * 60 * 60 * 24) : 0;
    },
    pacingValue(item) {
      let budget = item.budget ? item.budget : 0;
      let spend = this.spend(item);

      let value = (Number(spend) / Number(budget)) * 100;

      if (!this.superAdmin && value > 100) {
        value = 100;
      } else if (!isFinite(value) || isNaN(value)) {
        value = 0;
      }
      return value.toFixed(2);
    },
    pacingPerformance(item) {
      //get todays date
      let today = new Date();

      let startDate = new Date(item.start_date);
      // startDate = new Date(startDate[1] + "/" + startDate[0] + "/" + startDate[2]);

      let endDate = new Date(item.end_date);

      let days =
        (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);

      let dailyBudget = parseInt(item.budget.replace(/,/g, "")) / days;

      let runDays;

      if (today.getTime() < endDate.getTime()) {
        runDays =
          (today.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);
      } else {
        runDays =
          (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);
      }

      let expectedBudget = runDays * dailyBudget;

      let perfomance = ((this.spend(item) / expectedBudget) * 100).toFixed(2);

      let days_left = this.daysLeft(item.end_date);

      let perfomancedays = ((days_left / days) * 100).toFixed(0);
      // return `${perfomancedays} / ${perfomance}`;
      // console.log(startDate_1);

      if (perfomance < 50 && perfomancedays > 75) {
        return "grey";
      } else if (perfomance >= 50 && perfomance < 90 && perfomancedays < 80) {
        return "warning";
      } else if (perfomance >= 90) {
        return "success";
      } else return "error";
    },
    getColor(status) {
      if (status === "Paid") return "green";
      else if (status === "Credited") return "orange";
      else return "red";
    },
    handlePageChange(value) {
      this.pagination.page = value;
      this.filter_params.page = value;
      this.$emit("triggerPageChange", value);
    },

    viewReport(item) {
      this.$router.push({ name: "campaign", params: { id: item.campaign_id } });
    },

    checkEngagements(item) {
      if (item.campaign_type === "engagement") {
        return item.engagements;
      }

      return "-";
    },

    checkEngagementRate(item) {
      if (item.campaign_type === "engagement") {
        return item.engagement_rate;
      }

      return "-";
    },

    checkCPC(item) {
      if (
        item.campaign_type === "awareness" ||
        item.campaign_type === "Click To Action"
      ) {
        let cpc = item.cpc ? item.cpc.toFixed(2) : 0;
        if (!this.superAdmin) {
          cpc = this.spend(item) / item.clicks;
        }
        return "$" + cpc.toFixed(2);
      } else {
        return "-";
      }
    },

    checkCTR(item) {
      if (
        item.campaign_type === "display" &&
        item.campaign_objective === "awareness"
      ) {
        const ctr = item.ctr ? item.ctr.toFixed(2) : 0;
        return ctr + "%";
      }
      if (
        item.campaign_type === "display" &&
        item.campaign_objective === "traffic"
      ) {
        const ctr = item.ctr ? item.ctr.toFixed(2) : 0;
        return ctr + "%";
      }
      if (
        item.campaign_type === "awareness" ||
        item.campaign_type === "Click To Action"
      ) {
        const ctr = item.ctr ? item.ctr.toFixed(2) : 0;
        return ctr + "%";
      } else {
        return "-";
      }
    },

    checkClicks(item) {
      if (
        item.campaign_type === "awareness" ||
        item.campaign_type === "Click To Action"
      ) {
        const clicks = item.clicks ? item.clicks.toLocaleString() : 0;
        return clicks;
      }
      if (
        item.campaign_type === "display" &&
        item.campaign_objective === "awareness"
      ) {
        const clicks = item.clicks ? item.clicks.toLocaleString() : 0;
        return clicks;
      }
      if (
        item.campaign_type === "display" &&
        item.campaign_objective === "traffic"
      ) {
        const clicks = item.clicks ? item.clicks.toLocaleString() : 0;
        return clicks;
      } else {
        return "-";
      }
    },
    checkLeads(item) {
      if (
        item.campaign_type === "conversion" ||
        (item.campaign_type === "display" &&
          item.campaign_objective === "leads")
      ) {
        const leads = item.leads;
        return leads?.toLocaleString();
      } else {
        return "-";
      }
    },
    checkCPL(item) {
      if (
        item.campaign_type === "conversion" ||
        (item.campaign_type === "display" &&
          item.campaign_objective === "leads")
      ) {
        let cpl = item.cpl ? item.cpl.toFixed(2) : 0;
        if (!this.superAdmin) {
          cpl = (this.spend(item) / this.checkLeads(item)).toFixed(2);
        }
        return "$" + cpl;
      } else {
        return "-";
      }
    },
    checkViews(item) {
      if (item.campaign_type === "video") {
        const views = item.views ? item.views : 0;
        return views.toLocaleString();
      } else {
        return "-";
      }
    },
    checkVTR(item) {
      if (item.campaign_type === "video") {
        const vtr = item.vtr ? item.vtr : 0;
        return vtr + "%";
      } else {
        return "-";
      }
    },
  },

  computed: {
    dateRange() {
      return this.dates.join(" -- ");
    },
  },
  watch: {
    filter_params: {
      handler: function () {
        this.itemsPerPage = this.filter_params.per_page;
        this.$emit("filterParams", this.filter_params);
      },
      deep: true,
    },
  },
  created() {
    this.populateHeaders();
  },
};
</script>
